.dark {
    width: 448px;
    height: 20.5em;
}

.dark .ant-card-body{
    width: 448px;
    height: 16.3em;
    background-color: #282c34;
}

.CircularProgressbar.objective-completed .CircularProgressbar-path {
    stroke: darkorchid;
}

.CircularProgressbar.objective-completed .CircularProgressbar-text {
    fill: darkorchid;
}

.CircularProgressbar.objectiveOk-completed .CircularProgressbar-path {
    stroke: darkgreen;
}

.CircularProgressbar.objectiveOk-completed .CircularProgressbar-text {
    fill: darkgreen;
}

.icon-completed {
    font-size: 170px;
    color: darkmagenta;
}

.icon-completed-ok {
    font-size: 170px;
    color: darkgreen;
}

.icon-failed {
    font-size: 170px;
    color: darkred;
}
.icon-waiting {
    font-size: 190px;
    color: white;
}

.site-card-wrapper {
    margin: auto;
    /*width: 60%;*/
    text-align: center;
    /*border: 3px solid #73AD21;*/
    /*padding: 10px;*/
}

.site-card-wrapper .ant-typography{
    align-content: center;
    text-align: center;

}

.button_more_info {
    background: none;
    color: inherit;
    /*border: none;*/
    /*padding: 0;*/
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border: 1px solid white;
    border-radius: 20px;
    padding: 0px 7px;
}
